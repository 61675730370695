<template>
  <div>
    <head-layout  :head-title="'Kafka数据采集'" >
    </head-layout>
    <el-container class="new-sino-box">
      <el-header class="new-sino-header">
        <div >
          <el-input placeholder="主题" v-model="queryList.param1" class="new-sino-input" size="mini" />
          <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad">搜索</el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="addFn">新建</el-button>
        </div>
        <!--        <div>-->
        <!--          <el-button size="mini" type="primary" @click="addFn">新增</el-button>-->
        <!--          <el-button size="mini" type="danger" @click="deleteFn">删除</el-button>-->
        <!--        </div>-->
      </el-header>

      <el-main class="new-sino-main">
        <el-table
          v-tableHe
          size="mini"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          border
          :data="tableData"
          style="width: 100%">
          <el-table-column
            align="center"
            prop="infCode"
            label="目标服务器"
            width="180">
          </el-table-column>
          <el-table-column
            align="center"
            prop="tokenUrl"
            label="主题"
            width="180">
          </el-table-column>
          <el-table-column
            align="center"
            prop="tokenConfig"
            label="任务名称">
          </el-table-column>
          <el-table-column
            align="center"
            prop="requestMethod"
            label="是否超时">
            <template slot-scope="scope">
              {{scope.row.requestMethod==1?'get':'post'}}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="tokenPrefix"
            label="状态">
          </el-table-column>
          <el-table-column
            align="center"
            prop="tokenField"
            label="数据监控">
          </el-table-column>

          <el-table-column
            align="center"
            prop="address"
            label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-button
                size="mini"
                type="text"
                @click="handleEdit(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="float:right;height: 60px;">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryList.query.current"
            :page-size="queryList.query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </el-container>
    <el-dialog  width="960px"  title="新建任务"  :visible.sync="dialogFormVisible">
      <el-form :inline="true" :model="form" label-width="150px" :rules="rules" ref="ruleForm">
        <el-form-item label="目标服务器:" prop="infCode">
          <el-input  class = 'new-form-input' v-model="form.servers" size="mini" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="主题:" prop="tokenField">
          <el-input placeholder="字段名称" class = 'new-form-input' v-model="form.topic" size="mini" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="目标数据库:" prop="tokenUrl">
          <el-select  class = 'new-form-input' v-model="form.targetDB" size="mini" placeholder="请输入目标数据库">
            <el-option label="get" :value="1"></el-option>
            <el-option label="post" :value="2"></el-option>
          </el-select>        </el-form-item>
        <el-form-item label="目标表:" prop="requestMethod">
          <el-input placeholder="请输入目标表" class = 'new-form-input' v-model="form.targetTable" size="mini" autocomplete="off"></el-input>

        </el-form-item>
        <el-form-item label="自动更新时间:" prop="sourceId">
          <el-select  class = 'new-form-input' v-model="form.sourceId" size="mini" placeholder="请选择类型">
            <el-option v-for="(item,index) in selDat" :key="index" :label="item.sourceName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="注册重试次数:" >
          <el-input  placeholder="注册重试次数" class = 'new-form-input' v-model="form.tokenPrefix" size="mini" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述:" prop="sourceId">
          <el-select  class = 'new-form-input' v-model="form.context" size="mini" placeholder="描述">
            <el-option v-for="(item,index) in selDat" :key="index" :label="item.sourceName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消息群组:" >
          <el-input  placeholder="消息群组" class = 'new-form-input' v-model="form.groupid" size="mini" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="最大的offset:" >
          <el-input placeholder="最大的offset"  disabled class = 'new-form-input' v-model="form.autooffesetreset" size="mini" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="session过期时间:" prop="sourceId">
          <el-input placeholder="session过期时间"  disabled class = 'new-form-input' v-model="form.sessionTimeout" size="mini" autocomplete="off"></el-input>

        </el-form-item>
        <el-form-item label="状态:" >
          <el-input  placeholder="测试重试次数" class = 'new-form-input' v-model="form.tokenPrefix" size="mini" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否定期提交offset:" >
          <el-input placeholder="描述"  disabled class = 'new-form-input' v-model="form.config" size="mini" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="X轴:" >
          <el-select  class = 'new-form-input' v-model="form.sourceId" size="mini" placeholder="请选择类型">
            <el-option v-for="(item,index) in selDat" :key="index" :label="item.sourceName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Y轴:" prop="sourceId">
          <el-input placeholder="session过期时间"  disabled class = 'new-form-input' v-model="form.config" size="mini" autocomplete="off">
            <el-button slot="append" icon="el-icon-search"></el-button>

          </el-input>

        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveFn('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  tokenList,
  tokentUp,
  tokentDel,
  datSoSel,
  tokenInitcode,
  dbConnectionList
} from "@/api/dataAcquisition/index";
import { mapGetters } from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      activeName: '1',
      multipleSelection:[],
      selDat:[],
      tableData: [],
      total:0,
      queryList:{
        query:{
          current:1,
          size:10,
        },
        param1:''
      },
      form: null,
      formLabelWidth: '120px',
      dialogFormVisible:false,
      loading:false,
      rules: {
        infCode: [
          { required: true, message: '请输入接口编码', trigger: 'change' }
        ],
        tokenField: [
          { required: true, message: '请输入字段名称', trigger: 'change' }
        ],
        tokenUrl: [
          { required: true, message: '请输入接口地址', trigger: 'change' }
        ],
        requestMethod: [
          { required: true, message: '请选择请求方式', trigger: 'change' }
        ],
        sourceId :[
          { required: true, message: '请选择数据源id', trigger: 'change' }
        ],
      },
      headBtnOptions: [{
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
        icon: ""
      }, {
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-romve",
        type: "button",
        icon: ""
      }],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      tokenList(this.queryList).then(res => {
        this.loading = false;
        let obj = res.data.data;
        this.tableData = obj.records
        this.total = obj.total
      });
    },
    getKafKaData(){
      dbConnectionList().then(res=>{
        console.log(res)
      })
    },
    getAutoCodeFn(){
      tokenInitcode().then((res)=>{
        console.log(res);
        this.form.infCode = res.data.data;
      })
    },
    initForm(){
      this.form={
        infCode:'',
        tokenField:'',
        tokenUrl:'',
        requestMethod:'',
        sourceId:'',
        "headerList": [],
        "configList": [],
      }
    },
    datSoSelFn(){
      datSoSel().then((res)=>{
        this.selDat = res.data.data;
      })
    },
    addFn(){
      this.dialogFormVisible = true;
      this.initForm()
      this.getAutoCodeFn()
    },
    handleEdit(index,item){
      this.form = item;
      this.dialogFormVisible = true;
    },
    deleteFn(){
      if(this.multipleSelection.length == 0){
        this.$message.error('请选择删除数据')
        return
      }
      let ids = this.multipleSelection.map((item)=>{
        return item.id
      })
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        tokentDel(ids).then((res)=>{
          this.$message.success('删除成功');
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    saveFn(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.form.id){
            tokentUp(this.form).then(()=>{
              this.$message.success('修改成功！');
              this.dialogFormVisible = false;
              this.onLoad()
            })
          }else{
            tokentUp(this.form).then(()=>{
              this.$message.success('新增成功！');
              this.dialogFormVisible = false;
              this.onLoad()
            })
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },


    handleSizeChange(val) {
      this.queryList.size = val;
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.queryList.page = val;
      this.onLoad()
    },

    headerListDelFn(index){
      this.form.headerList.splice(index,1)
    },
    headerconfigListAddFn(){
      this.form.configList.push({key:'',value:'',type:null})
    },
    headerconfigListDelFn(index){
      this.form.configList.splice(index,1)
    }
  },
  created() {
    this.onLoad();
    this.initForm();
    this.datSoSelFn()
    this.getKafKaData()
  },
  mounted() {
  },
};
</script>
<style>
.el-button+.el-button {
  margin-left: 0px;
}
</style>


